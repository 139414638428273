






























































































































































































































































































































































































































































































































































































































































































































.el-form-questionType {
  display: flex;
}

.el-form-item__content {
  display: flex;
  align-items: center;

  .el-radio-group .el-form-item /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }

  .checkbox /deep/ .el-form-item__content {
    display: flex;
    align-items: center;

    .el-checkbox {
      display: flex;
      align-items: center;

      .el-checkbox__label {
        display: flex;
        align-items: center;

        .el-input {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

.delet {
  margin-left: 0.5rem;
}

.el-form-item__content .img-el-upload /deep/ .el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
