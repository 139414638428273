




































































































































































































































































































































































































































































































































































































































































































.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}

.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.el-form-questionType /deep/ .el-form-item__content {
  margin-left: 0 !important;
}

.w-e-text-container {
  height: 250px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
